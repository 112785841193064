<template>
  <v-container max-width="600px" min-width="360px">
    <v-layout flex align-center justify-center>
      <v-form ref="form" @submit.prevent="login">
        <v-card>
          <v-card-title>User Login</v-card-title>

          <v-card-text>
            <v-text-field
              label="Email"
              prepend-icon="mdi-account"
              v-model="form.email"
              required
            ></v-text-field>

            <!-- <div class="text-right text-body-2">
      <router-link :to="{ name: 'forgot_password' }">
        {{ $t("auth.forgot_password") }} &gt;
      </router-link>
    </div> -->

            <v-text-field
              label="Password"
              prepend-icon="mdi-lock"
              type="password"
              v-model="form.password"
              required
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <div class="text-center">
              <v-btn :loading="loading" color="primary" type="submit"
                >Login</v-btn
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "login",
  data: () => ({
    form: {
      email: null,
      password: null,
    },
    errorMessages: {},
    loading: false,
    show1: false,
    authuser:false
  }),

  computed:{
    ...mapGetters(["authuser/getAuth"])
  },

  methods: {
    async login() {
      await this.$store.dispatch("authuser/login", this.form);
      await this.verifylogin()
    },

    async verifylogin(){
      this.authuser=await this['authuser/getAuth'];
       if(this.authuser) {
        this.$toast.success("Login Successfully");
        this.$router.push({ name: "Dashboard" });
      } else {
        this.$toast.error("Failed to login");
      }
    }
  },
};
</script>